.timeTable
    margin-right: 12px
    display: flex
    align-items: center

.active
    color: #007ffb

.activeButton
    display: flex
    flex-direction: column
    padding: 0 16px
    // 48px - Баннер
    margin: calc(-2px + 48px) 0 0
    background: #fff
    list-style: none
    width: 100%
    color: #007ffb
    &Item
        position: relative
        border-bottom: 1px solid var(--border-color)

    &Item:last-child
        border-bottom: none

    &Item *, button
        width: 100%
        display: flex
        border: none
        font-weight: bold
        font-size: 14px
        padding-top: 16px
        cursor: pointer
        padding-bottom: 16px
        text-decoration: none
        color: #007ffb

    &Item a:active a:visited a
        color: #007ffb

    &Item::after
        content: ''
        display: flex
        position: absolute
        right: 0
        top: 20px
        width: 10px
        height: 10px
        transform: rotate(137deg)
        border-top: 2px solid #007ffb
        border-left: 2px solid #007ffb

.timeTableContent
    display: flex
    align-items: center

.InTimeTable
    display: flex
    align-items: center

.timeTableNone
    display: flex
    align-items: center
    text-decoration: none

.socialIcon
    margin-left: 16px

    a
        display: block

.wrapper
    flex: 0 0 auto
    position: fixed
    width: 100%
    height: 86px
    display: grid
    grid-template-rows: 39px 1fr
    z-index: 3
    &Banner
        flex: 0 0 auto
        position: fixed
        width: 100%
        height: 86px
        z-index: 20
        display: grid
        grid-template-rows: 46px 38px 38px


.displayFlex
    display: flex
.displayNone
    display: none

.contactsBar
    background-color: #f3f6f9
    justify-content: space-between
    display: flex
    align-items: center
    height: 40px
    padding: 0 16px

.contactsBarBf
    background-color: #000000
    justify-content: space-between
    display: flex
    align-items: center
    height: 40px
    padding: 0 16px

.phoneWatsApp
    display: flex
    align-items: center

.headerNav
    background: #fff
    display: flex
    align-items: center
    padding: 0 16px
    min-height: 56px
    justify-content: space-between

.headerNavBf
    background: #000
    display: flex
    align-items: center
    padding: 0 16px
    min-height: 65px
    justify-content: space-between

.actions
    margin-left: auto
    position: relative
    z-index: 1

.hamburgerBf
    cursor: pointer
    transition-property: opacity, filter
    transition-duration: 0.15s
    transition-timing-function: linear
    font: inherit
    color: #FFF !important
    text-transform: none
    border: 0
    margin: 0
    overflow: visible
    background-color: #000000 !important
    min-height: 36px
    min-width: 36px
    margin-left: 4px
    display: flex
    align-items: center
    justify-content: center

    &:hover
        opacity: 0.7

    &.isOpen:hover
        opacity: 0.7

    &.isOpen .hamburgerBfInner,

    &.isOpen .hamburgerBfInner::before,

    &.isOpen .hamburgerBfInner::after
        background-color: #fff !important

    &Box
        width: 18px
        height: 18px
        display: inline-block
        position: relative

    &Inner
        display: block
        top: 50%
        margin-top: -2px

    &Inner, .hamburgerBfInner::before, .hamburgerBfInner::after
        width: 18px
        height: 2px
        background-color: #fff !important
        position: absolute
        transition-property: transform
        transition-duration: 0.15s
        transition-timing-function: ease

    &Inner::before, .hamburgerBfInner::after
        content: ""
        display: block

    &Inner::before
        top: -6px

    &Inner::after
        bottom: -6px

.hamburgerSpin .hamburgerBfInner
    transition-duration: 0.22s
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

    &::before
        transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in

    &::after
        transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)

.hamburgerSpin.isOpen .hamburgerBfInner
    transform: rotate(225deg)
    transition-delay: 0.12s
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

    &::before
        top: 0
        opacity: 0
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out

    &::after
        bottom: 0
        transform: rotate(-90deg)
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)

.hamburger
    cursor: pointer
    transition-property: opacity, filter
    transition-duration: 0.15s
    transition-timing-function: linear
    font: inherit
    color: inherit
    text-transform: none
    border: 0
    margin: 0
    overflow: visible
    background-color: #F4F6F9
    min-height: 36px
    min-width: 36px
    margin-left: 4px
    display: flex
    align-items: center
    justify-content: center

    &:hover
        opacity: 0.7

    &.isOpen:hover
        opacity: 0.7

    &.isOpen .hamburgerInner,

    &.isOpen .hamburgerInner::before,

    &.isOpen .hamburgerInner::after
        background-color: var(--primary-color)

    &Box
        width: 18px
        height: 18px
        display: inline-block
        position: relative

    &Inner
        display: block
        top: 50%
        margin-top: -2px

    &Inner, .hamburgerInner::before, .hamburgerInner::after
        width: 18px
        height: 2px
        background-color: var(--primary-color)
        position: absolute
        transition-property: transform
        transition-duration: 0.15s
        transition-timing-function: ease

    &Inner::before, .hamburgerInner::after
        content: ""
        display: block

    &Inner::before
        top: -6px

    &Inner::after
        bottom: -6px
       
.hamburgerSpin .hamburgerInner
    transition-duration: 0.22s
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

    &::before
        transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in

    &::after
        transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)

.hamburgerSpin.isOpen .hamburgerInner
    transform: rotate(225deg)
    transition-delay: 0.12s
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

    &::before
        top: 0
        opacity: 0
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out

    &::after
        bottom: 0
        transform: rotate(-90deg)
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)

.mobileDropDown
    z-index: 22
    width: 100%
    margin-top: 96px
    display: none

.openMenu
    display: flex
    overflow: scroll
    position: fixed
    flex-direction: column
    background: var(--shadow-primary-color)
    height: calc(100vh - 20px)

.openMenuBf
    display: flex
    overflow: scroll
    position: fixed
    flex-direction: column
    background: #000
    height: calc(100vh - 20px)

.mobileDropDownListActive
    display: flex
    flex-direction: column
    padding: 0 16px
    // 48px - Баннер
    margin: calc(-2px + 48px) 0 0
    background: #fff
    list-style: none
    width: 100%
    color: #007ffb

    &Item
        position: relative
        border-bottom: 1px solid var(--border-color)
        color: #007ffb
        padding-left: 0

    &Item:last-child
        border-bottom: none

    &Item *, button
        width: 100%
        display: flex
        border: none
        font-weight: bold
        font-size: 14px
        padding-top: 16px
        padding-bottom: 16px
        text-decoration: none
        color: #007ffb

    &Item a:active a:visited a
        color: #007ffb

    &Item::after
        content: ''
        display: flex
        position: absolute
        right: 0
        top: 20px
        width: 10px
        height: 10px
        transform: rotate(137deg)
        border-top: 2px solid var(--primary-color)
        border-left: 2px solid var(--primary-color)
        color: #007ffb

.mobileDropDownList
    display: flex
    flex-direction: column
    padding: 0 16px 8px
    // 48px - Баннер
    margin: calc(-2px + 48px) 0 0
    background: #fff
    list-style: none
    width: 100%

    &Item
        position: relative
        border-bottom: 1px solid var(--border-color)
        padding-left: 0

    &Item:last-child
        border-bottom: none

    &Item *, button
        width: 100%
        display: flex
        border: none
        font-weight: bold
        font-size: 14px
        padding-top: 16px
        cursor: pointer
        padding-bottom: 16px
        text-decoration: none

    &Item::after
        content: ''
        display: flex
        position: absolute
        right: 0
        top: 20px
        width: 10px
        height: 10px
        transform: rotate(137deg)
        border-top: 2px solid var(--primary-color)
        border-left: 2px solid var(--primary-color)
        

.mobileDropDownListBf
    display: flex
    flex-direction: column
    padding: 0 16px 8px
    // 48px - Баннер
    margin: calc(-2px + 48px) 0 0
    background: #000
    list-style: none
    width: 100%

    &Item
        position: relative
        border-bottom: 1px solid var(--border-color)
        padding-left: 0

    &Item:last-child
        border-bottom: none

    &Item *, button
        width: 100%
        display: flex
        border: none
        font-weight: bold
        font-size: 14px
        padding-top: 16px
        cursor: pointer
        padding-bottom: 16px
        text-decoration: none
        color: #fff

    &Item::after
        content: ''
        display: flex
        position: absolute
        right: 0
        top: 20px
        width: 10px
        height: 10px
        transform: rotate(137deg)
        border-top: 2px solid #fff !important
        border-left: 2px solid #fff !important

.bottomInfo
    background-color: var(--shadow-primary-color)
    display: flex
    flex-direction: column
    margin-top: auto
    align-content: flex-end
    width: 100%

.bottomInfoBf
    background-color: #000 !important
    display: flex
    flex-direction: column
    margin-top: auto
    align-content: flex-end
    width: 100%

.innerWrapper
    text-align: center
    margin-bottom: 80px

.socialBlock
    display: flex
    align-items: center
    justify-content: center
    column-gap: 16px

.copyright
    text-align: center
    margin-top: 30px

    &Bf
        text-align: center
        margin-top: 30px
        color: #fff



.subDropdown
    background-color: #fff
    width: 100%
    min-height: 100px

.mobileItem
    position: relative
    height: auto
    padding-left: 15px
    padding-right: 15px
    top: -318px
    background-color: #fff
    display: none
    flex-direction: column
    align-items: flex-start
    width: 100%

    &Open
        display: flex

.mobileItemBf
    position: relative
    height: auto
    padding-left: 15px
    padding-right: 15px
    top: -318px
    background-color: #000 !important
    display: none
    flex-direction: column
    align-items: flex-start
    width: 100%

    &Open
        display: flex

.mobileItemSea
    position: relative
    height: auto
    padding-left: 15px
    padding-right: 15px
    top: -266px
    background-color: #fff
    display: none
    flex-direction: column
    align-items: flex-start
    width: 100%

    &Open
        display: flex

.subDropdown
    background-color: #fff
    width: 100%
    min-height: 100px

.backMenu
    width: 100%
    text-align: start
    font-weight: bold
    padding-left: 25px
    padding-bottom: 16px
    padding-top: 16px
    border: none
    display: flex
    align-items: center
    border-bottom: 1px solid var(--border-color)
    position: relative

    &::before
        content: ""
        display: flex
        position: absolute
        left: 4px
        width: 10px
        height: 10px
        transform: rotate(-46deg)
        border-top: 2px solid var(--primary-color)
        border-left: 2px solid var(--primary-color)


.backMenuBf
    width: 100%
    text-align: start
    font-weight: bold
    padding-left: 25px
    padding-bottom: 16px
    padding-top: 16px
    border: none
    display: flex
    align-items: center
    border-bottom: 1px solid var(--border-color)
    position: relative
    color: #fff

    &::before
        content: ""
        display: flex
        position: absolute
        left: 4px
        width: 10px
        height: 10px
        transform: rotate(-46deg)
        border-top: 2px solid #fff !important
        border-left: 2px solid #fff !important

.modileDisable
    padding-left: 0

.allLinks
    width: 100%
    display: flex
    border: none
    font-weight: bold
    font-size: 14px
    padding-top: 16px
    cursor: pointer
    padding-bottom: 16px
    text-decoration: none
    position: relative
    border-bottom: 1px solid var(--border-color)
    color: var(--primary-color)

    &::after
        content: ''
        display: flex
        position: absolute
        right: 2px
        top: 23px
        width: 10px
        height: 10px
        transform: rotate(137deg)
        border-top: 2px solid var(--primary-color)
        border-left: 2px solid var(--primary-color)


.allLinksBf
    width: 100%
    display: flex
    border: none
    font-weight: bold
    font-size: 14px
    padding-top: 16px
    cursor: pointer
    padding-bottom: 16px
    text-decoration: none
    position: relative
    border-bottom: 1px solid var(--border-color)
    color: #fff !important

    &::after
        content: ''
        display: flex
        position: absolute
        right: 2px
        top: 23px
        width: 10px
        height: 10px
        transform: rotate(137deg)
        border-top: 2px solid #fff
        border-left: 2px solid #fff

.featureList
    padding-left: 0
    border-bottom: 1px solid var(--border-color)

    &:last-child
        border: none

    li
        width: 100%
        display: inline-block
        padding-bottom: 16px

    a
        padding: 0
        width: 100%
        display: flex
        align-items: center
.season
    margin-top: 16px
    margin-bottom: 16px
    &Block
        display: grid
        grid-template-columns: 1fr 1fr 1fr
    &Item
        &:hover
            color: #026ED9

    &Bf 
        color: #fff

.arrowRight
    margin-left: 8px

.colorText
    color: #fff

.itemWrapper
    display: flex
    margin-top: 32px

    &Bf
        display: flex
        margin-top: 32px
        color: #fff !important

.infoWrapper
    padding-top: 16px
    padding-bottom: 0

    &Bf
        padding-top: 16px
        padding-bottom: 0
        color: #fff

.seasonItemWrapper
    display: flex
    padding-top: 16px

.arrowRightMobile
    display: flex
    flex-direction: row
    align-items: center
    padding-bottom: 1px
    padding-left: 8px

    &Bf
        display: flex
        flex-direction: row
        align-items: center
        padding-bottom: 1px
        padding-left: 8px
        color: #fff !important
